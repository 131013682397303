import React, { useState, useEffect } from 'react';
import { CssBaseline, Container, Box, Typography, Tabs, Tab, Grid, Card, CardMedia, CardContent, Avatar } from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import './HomePage.css';
import '../components/ProductCard.js';
import Carousel from '../components/Carousel';
import axios from '../axiosConfig'; // Import the configured Axios instance
import ProductCard from '../components/ProductCard.js';

const minerals = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886112/minerals_u8drr7.jpg";
const vitamins = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886113/vitamins_c2olat.jpg";
const herbs = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886115/herbs_deqg15.jpg";
const supplements = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886113/supplements_kesk6s.jpg";
const lifestyle = "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721886112/lifestyle_b7nqig.jpg";



const slides = [
  // { image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1621639445/banner1_mz3jx0.jpg", link: '/category/All' },
  { image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1621639448/banner2_u1xnia.jpg", link: '/category/All' },
  { image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1621639441/banner3_ymymvm.jpg", link: '/category/All' },
  { image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1621639441/banner4_b80l1e.jpg", link: '/category/All' },
  { image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1621639448/banner2_u1xnia.jpg", link: '/category/All' },
  { image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1621639441/banner3_ymymvm.jpg", link: '/category/All' },
  { image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1621639441/banner4_b80l1e.jpg", link: '/category/All' },

  // { image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1621639443/banner5_qlz2gs.jpg", link: '/category/All' },
];



const infoCards = [
  {
    title: 'Clean Ingredients',
    description: 'We believe in transparency and quality. Our supplements are crafted using clean ingredients, ensuring they are GMO-free, gluten-free, and free from artificial flavors and colors.',
    image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721818603/Website_page-0001_kryglg.jpg",
    badge: 1
  },
  {
    title: 'Your Health, Our Priority',
    description: 'At Orin International, your health is our priority. As a leading supplement retailer, we are dedicated to offering products that prioritize quality, efficacy, and customer satisfaction. Explore our range today to support your wellness journey with confidence.',
    image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721818603/Website_page-0002_cz2vs5.jpg",
    badge: 2
  },
  {
    title: 'Product Efficacy',
    description: 'Discover our range of supplements scientifically formulated for maximum efficacy, designed to support your health and wellness goals. Each product is crafted using premium ingredients to ensure superior results and customer satisfaction.',
    image: "https://res.cloudinary.com/dv71k4jqz/image/upload/f_auto/v1721818602/Website_page-0003_pfwg2c.jpg",
    badge: 3
  }
];


const testimonials = [
  { text: 'This product has changed my life for the better!', author: 'Clara Davis', avatar: 'https://via.placeholder.com/150' },
  { text: 'I have never felt better. Highly recommended!', author: 'Junaid Shah', avatar: 'https://via.placeholder.com/150' },
  { text: 'Amazing results. Will definitely buy again.', author: 'Diana Russels', avatar: 'https://via.placeholder.com/150' },
];

const slides2 = [
  { image: minerals, link: '/category/Mineral' },
  { image: vitamins, link: '/category/Vitamin' },
  { image: herbs, link: '/category/Herb' },
  { image: supplements, link: '/category/Supplement' },
  { image: lifestyle, link: '/category/Lifestyle' },
];

const HomePage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    axios.get('/featured')
      .then(response => {
        const formattedTabs = response.data.map(tab => ({
          label: tab.tagline,
          products: tab.products,
        }));
        setTabs(formattedTabs);
      })
      .catch(error => {
        console.error('Error fetching the featured products:', error);
      });
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <CssBaseline />
      <div className="home">
        <Box className="carousel-container">
          <Swiper
            loop={true}
            pagination={{ clickable: true }}
            navigation={true}
            className="simpleCarousel"
            autoplay={{
              delay: 5000, // Delay between transitions in milliseconds
              disableOnInteraction: false, // Continue autoplay after user interaction
            }}
            slidesPerView={1} 
            slidesPerGroup={1}
            modules={[Pagination, Navigation, Autoplay]}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <Link to={slide.link}>
                  <img src={slide.image} alt={`Slide ${index}`} />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <Container>
          {tabs.length > 0 && (
            <Box sx={{ mb: 4, mt: 4 }}>
              <Typography variant="h4" component="h2" align="center" gutterBottom>
                I need help with&nbsp;
                <Typography variant="h4" component="span" color="primary">
                  {tabs[selectedTab].label}
                </Typography>
              </Typography>
              <Box className="tabs-container">
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  centered
                  classes={{ root: 'tabs-root', indicator: 'tabs-indicator' }}
                >
                  {tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} classes={{ root: 'tab-root', selected: 'tab-selected' }} />
                  ))}
                </Tabs>
              </Box>
              

              <Box sx={{ p: 3 }}>
                <Grid container spacing={2}>
                  {tabs[selectedTab].products.map((product, index) => (
                    <Grid item xs={12} sm={4} key={index}>
                      <ProductCard key={product._id} product={product} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          )}

          <Box sx={{ mt: 8 }}>
            <Typography variant="h4" component="h2" gutterBottom align="center">
              Orin International: Your Health, Our Priority
            </Typography>
            <Grid container spacing={2}>
              {infoCards.map((info, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Box className="info-card" style={{ backgroundImage: `url(${info.image})` }}>
                    {/* <Box className="badge">{info.badge}</Box> */}
                    <Box className="info-card-content">
                      {/* <Typography variant="h6" component="h3">
                        {info.title}
                      </Typography> */}
                      <Typography variant="body2" color="white">
                        {info.description}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Container sx={{ mt: 8 }}>
            <Typography variant="h4" component="h2" gutterBottom align="center">
              Explore Our Extensive Collection
            </Typography>
            <Carousel slides={slides2} />
          </Container>

          <Box sx={{ mt: 8 }}>
            <Typography variant="h4" component="h2" gutterBottom align="center">
              Testimonials
            </Typography>
            <Grid container spacing={2}>
              {testimonials.map((testimonial, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Card className="testimonial-card">
                    <CardContent>
                      <FormatQuoteIcon className="quote-icon" />
                      <Typography variant="body1" component="p">
                        "{testimonial.text}"
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                        <Avatar src={testimonial.avatar} alt={testimonial.author} sx={{ mr: 2 }} />
                        <Typography variant="body2" component="p" color="text.secondary">
                          - {testimonial.author}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </div>
    </>
  );
};

export default HomePage;
