import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import axios from '../axiosConfig';
import './PriceDisplay.css';

const PriceDisplay = ({ productId, price, discount: propDiscount, typographyVariant = 'h6' }) => {
  const [discount, setDiscount] = useState(propDiscount || 0);

  useEffect(() => {
    if (propDiscount === undefined) {
      const fetchDiscount = async () => {
        try {
          const response = await axios.get(`/sales/discount/${productId}`);
          setDiscount(response.data.maxDiscount);
        } catch (error) {
          console.error('Error fetching discount:', error);
        }
      };

      fetchDiscount();
    }
  }, [productId, propDiscount]);

  const discountPrice = Math.floor(price - price * discount / 100);

  return (
    <Box className="price-display">
      {discount > 0 ? (
        <>
          <Typography variant={typographyVariant} sx={{ marginRight: 2, color: 'red' }}>
            {`$${(discountPrice / 280).toFixed(2)}`}
          </Typography>
          <Typography variant={typographyVariant} sx={{ textDecoration: 'line-through', color: 'gray' }}>
            {`$${(price / 280).toFixed(2)}`}
          </Typography>
        </>
      ) : (
        <Typography variant={typographyVariant} sx={{ color: 'red' }}>{`$${(price / 280).toFixed(2)}`}</Typography>
      )}
    </Box>
  );
  
};

export default PriceDisplay;
