import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, TextField, Button, Snackbar, Alert, Grid, Paper, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import './Cart.css';

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [promoCode, setPromoCode] = useState('');
  const [promoDiscount, setPromoDiscount] = useState(0); // Add state for promo discount
  const [totalAmount, setTotalAmount] = useState(0); // Add state for total amount after discount
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCartData = async () => {
      const storedCart = localStorage.getItem('cart');
      if (storedCart) {
        const parsedCart = JSON.parse(storedCart).map(item => ({ ...item, valid: true }));
        await validateCartItems(parsedCart);
        setTotalAmount(getTotalCartAmount());
        setCartItems(parsedCart);
      }
      setLoading(false);
    };

    fetchCartData();
  }, []);

  const validateCartItems = async (cartItems) => {
    const token = localStorage.getItem('token');
    const headers = token ? { Authorization: `Bearer ${token}` } : {};

    const updatedCartItems = await Promise.all(cartItems.map(async (item) => {
      const maxDiscountResponse = await axios.get(`/sales/discount/cart/${item.id || item._id}`, { headers });
      const maxDiscount = maxDiscountResponse.data.maxDiscount;

      const availabilityResponse = await axios.post(`/products/check-availability`, { id: item.id || item._id, quantity: item.amount });
      const { isOutOfStock, availableQuantity } = availabilityResponse.data;

      item.availableQuantity = availableQuantity;

      if (item.discount == undefined || item.discount < maxDiscount) {
        item.discount = maxDiscount;
      }

      if (isOutOfStock) {
        item.valid = false;
      }

      return item;
    }));

    setCartItems(updatedCartItems);
    localStorage.setItem('cart', JSON.stringify(updatedCartItems));
  };

  const updateCart = (newCartItems) => {
    setCartItems(newCartItems);
    localStorage.setItem('cart', JSON.stringify(newCartItems));
  };

  const handleAmountChange = async (productId, newAmount) => {
    const newCartItems = cartItems.map(async (item) => {
      if (item.id === productId || item._id === productId) {
        if (newAmount > item.availableQuantity) {
          setSnackbarMessage(`Only ${item.availableQuantity} items available in stock`);
          setSnackbarSeverity('warning');
          setSnackbarOpen(true);
          return { ...item, amount: item.availableQuantity };
        } else {
          const availabilityResponse = await axios.post(`/products/check-availability`, { id: item.id || item._id, quantity: newAmount });
          const { availableQuantity } = availabilityResponse.data;
          return { ...item, amount: newAmount, availableQuantity };
        }
      }
      return item;
    });
    const resolvedCartItems = await Promise.all(newCartItems);
    updateCart(resolvedCartItems);
  };

  const removeFromCart = (productId) => {
    const newCartItems = cartItems.filter(item => item.id !== productId && item._id !== productId);
    updateCart(newCartItems);
    setSnackbarMessage('Product removed from cart.');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
  };

  const clearCart = () => {
    updateCart([]);
    setSnackbarMessage('Cart cleared.');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
  };

  const getSubtotal = () => {
    const validCartItems = cartItems.filter(item => item.valid);
    return validCartItems.reduce((total, product) => total + product.price * product.amount, 0);
  };

  const getDiscountAmount = () => {
    const validCartItems = cartItems.filter(item => item.valid);
    return validCartItems.reduce((total, product) => total + (product.price * product.discount / 100) * product.amount, 0);
  };

  const getTotalCartAmount = () => {
    const validCartItems = cartItems.filter(item => item.valid);
    return validCartItems.reduce((total, product) => total + (product.price - product.price * product.discount / 100) * product.amount, 0);
  };

  const updateTotalAmount = (discountPercentage) => {
    const totalAmount = getTotalCartAmount();
    const discountedAmount = totalAmount * (discountPercentage / 100);
    setTotalAmount(totalAmount - discountedAmount);
  };

  const handleCheckout = () => {
    // Filter valid cart items with count > 0
    const validCartItems = cartItems.filter(item => item.valid && item.amount > 0);
    
    if (validCartItems.length === 0) {
      setSnackbarMessage('Please add items with a valid quantity to proceed to checkout.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }
  
    navigate('/checkout', { state: { cartItems: validCartItems, promoCode, promoDiscount, totalAmount } });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handlePromoCodeSubmit = async () => {
    try {
      const response = await axios.post('/promocode/validate', { code: promoCode });
      if (response.data.valid) {
        setSnackbarMessage('Promo code applied successfully!');
        setSnackbarSeverity('success');
        setPromoDiscount(response.data.discountPercentage);
        updateTotalAmount(response.data.discountPercentage); // Adjust the total amount with the discount
      } else {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage('Error applying promo code');
      setSnackbarSeverity('error');
    }
    setSnackbarOpen(true);
  };

  if (loading) {
    return (
      <Box className="cart-container" display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className="cart-container">
      <Typography align="center" variant="h6" className="cart-title2">Free Shipping on Orders over Rs15,000/-</Typography>
      <Box className="cart-header">
        
        <Typography align="center" variant="h4" className="cart-title">Shopping Cart</Typography>
        {cartItems.length > 0 && (
          <Button onClick={clearCart} className="clear-cart-icon" variant="contained" color="warning">
            Empty Cart <DeleteIcon />
          </Button>
        )}
      </Box>
      {cartItems.length === 0 ? (
        <Typography align="center" variant="h6">Your cart is empty. Start adding products!</Typography>
      ) : (
        <>
          <Grid container spacing={2}>
            {cartItems.map((product) => (
              <Grid item xs={12} key={product.id || product._id}>
                <Paper className={`cart-item ${product.valid ? '' : 'out-of-stock'}`}>
                  {!product.valid && (
                    <Box className="out-of-stock-overlay">
                      <Typography variant="h4" className="out-of-stock-text">Out of Stock</Typography>
                    </Box>
                  )}
                  <Grid container spacing={2} alignItems="center">
                  <Grid item xs={4} sm={2}>
                    <img className="cart-item-image" src={`${product.image.split('/upload/')[0]}/upload/f_auto/${product.image.split('/upload/')[1]}`} alt={product.name} />
                  </Grid>

                    <Grid item xs={8} sm={4}>
                      <Typography variant="body1">{product.name}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={2}>
                      <Typography variant="body2" className="cart-item-price">
                        {product.discount > 0 ? (
                          <>
                            <span className="discounted-price">{`$${((product.price - product.price * product.discount / 100)/280).toFixed(2)}`}</span>
                            <span className="original-price">{`$${(product.price/280).toFixed(2)}`}</span>
                          </>
                        ) : (
                          `$${(product.price/280).toFixed(2)}`
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={2} className="cart-item-quantity-container">
                      <IconButton onClick={() => handleAmountChange(product.id || product._id, product.amount - 1)}>
                        <RemoveIcon />
                      </IconButton>
                      <TextField
                        variant="outlined"
                        type="number"
                        value={product.amount}
                        onChange={(e) => handleAmountChange(product.id || product._id, parseInt(e.target.value))}
                        InputProps={{ inputProps: { min: 1 } }}
                        className="cart-item-quantity"
                      />
                      <IconButton onClick={() => handleAmountChange(product.id || product._id, product.amount + 1)}>
                        <AddIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <IconButton onClick={() => removeFromCart(product.id || product._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>

          <Box className="cart-summary-container">
            <Paper className="cart-total">
              <Typography variant="h6">Cart Summary</Typography>
              <Box className="cart-total-item">
                <Typography>Subtotal</Typography>
                <Typography>{`$${(getSubtotal()/280).toFixed(2)}`}</Typography>
              </Box>
              <Box className="cart-total-item">
                <Typography>Discount</Typography>
                <Typography>{`- $${(getDiscountAmount()/280).toFixed(2)}`}</Typography>
              </Box>
              <Box className="cart-total-item">
                <Typography>Additional Discount</Typography>
                <Typography>{`- $${((getTotalCartAmount()/280).toFixed(2) * (promoDiscount / 100)).toFixed(2)}`}</Typography>
              </Box>
              <Box className="cart-total-item">
                <Typography variant="h6">Total</Typography>
                <Typography variant="h6">{`$${((getSubtotal()/280).toFixed(2) - (getDiscountAmount()/280).toFixed(2) - (((getTotalCartAmount()/280).toFixed(2) * (promoDiscount / 100)).toFixed(0)))}`}</Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCheckout}
                className="checkout-button"
                disabled={cartItems.every(item => !item.valid)}
              >
                Proceed to Checkout
              </Button>
            </Paper>

            <Paper className="promo-code">
              <Typography variant="body1">Have a promo code?</Typography>
              <Box className="promo-code-box">
                <TextField
                  placeholder="Promo code"
                  variant="outlined"
                  fullWidth
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                />
                <Button variant="contained" color="primary" onClick={handlePromoCodeSubmit}>Apply</Button>
              </Box>
            </Paper>
          </Box>
        </>
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Cart;
